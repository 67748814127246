import {GlobalPrivilegeModel, LicenseModel, PrivilegeModel, ProjectResourceType} from '@core/api';
import {Organization} from '@core/organization';
import {every} from 'underscore';

import {
    PrivilegeDomains,
    PrivilegeLevels,
    PrivilegeOwners,
    PrivilegesConstants,
    PrivilegeTypes,
    PrivilegeUtils,
} from '../../privileges';

export class UserPrivilegesValidator {
    constructor(
        private privileges: PrivilegeModel[],
        private organization: Organization,
    ) {}

    get canEditLicense(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ManageLicense,
        });
    }

    get canEditCustomerLicense(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ManageCustomerLicense,
        });
    }

    get canEditCustomerLicenseOverrides(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ManageCustomerLicenseOverrides,
        });
    }

    get isRankingScriptEditor(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryPipelineScriptEditor,
            type: '',
        });
    }

    get isUaManager(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.Reports,
            type: PrivilegeTypes.Edit,
        });
    }

    get canSeeNamedFilters(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.NamedFilters,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditNamedFilters(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.NamedFilters,
            type: PrivilegeTypes.Edit,
        });
    }

    get canSeePermissionFilters(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.PermissionFilters,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditPermissionFilters(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.PermissionFilters,
                type: PrivilegeTypes.Edit,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Group,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditDimensions(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.CustomDimensions,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditExports(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.Exports,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditMetricAlerts(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.MetricAlerts,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditGroups(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Group,
            type: PrivilegeTypes.Edit,
        });
    }

    get canCreateGroups(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Group,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditAllGroups(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Group,
            type: PrivilegeTypes.Edit,
            targetId: PrivilegesConstants.allTargetIds,
        });
    }

    get canViewGroups(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Group,
            type: PrivilegeTypes.View,
        });
    }

    get canViewRealms(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SAMLIdentityProvider,
            type: PrivilegeTypes.View,
        });
    }

    get canViewOrganization(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Organization,
            type: PrivilegeTypes.View,
        });
    }

    get canEditOrganization(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Organization,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditOrganizationAdministration(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.OrganizationAdministration,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewOrganizationAdministration(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.OrganizationAdministration,
            type: PrivilegeTypes.View,
        });
    }

    get canEditGlobalGroups(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.GlobalGroup,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewGlobalGroup(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.GlobalGroup,
            type: PrivilegeTypes.View,
        });
    }

    get canCreateTemporaryAccessRequest(): boolean {
        return this.hasPrivilege({
            level: PrivilegeLevels.Global,
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.TemporaryAccessRequest,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditTemporaryAccessRequest(): boolean {
        return this.hasPrivilege({
            level: PrivilegeLevels.Global,
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.TemporaryAccessRequest,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewTemporaryAccessRequest(): boolean {
        return this.hasPrivilege({
            level: PrivilegeLevels.Global,
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.TemporaryAccessRequest,
            type: PrivilegeTypes.View,
        });
    }

    get canViewCrawlingModule(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.CrawlingModules,
            type: PrivilegeTypes.View,
        });
    }

    get canEditCrawlingModule(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.CrawlingModules,
            type: PrivilegeTypes.View,
        });
    }

    get canViewVisitBrowser(): boolean {
        return this.hasAllPrivileges([
            {
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            },
            {
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.PermissionFilters,
                type: PrivilegeTypes.View,
            },
            {
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            },
            {
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.NamedFilters,
                type: PrivilegeTypes.View,
            },
        ]);
    }

    get canSeeReports(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.NamedFilters,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.Reports,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canSeeAllReports(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.ViewAllReports,
                targetId: PrivilegesConstants.allTargetIds,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.NamedFilters,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditReports(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.Reports,
            type: PrivilegeTypes.Edit,
        });
    }

    get canAdministrateUA(): boolean {
        return this.hasPrivilege({
            targetDomain: PrivilegeDomains.Administrate,
            owner: PrivilegeOwners.UsageAnalytics,
        });
    }

    get canViewDimensions(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canViewDataHealth(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canViewExports(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.Exports,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canViewIndexes(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Index,
                type: PrivilegeTypes.View,
            }) ||
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.ElasticIndex,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditIndexes(): boolean {
        return (
            this.hasWritePrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Index,
                type: PrivilegeTypes.Edit,
            }) ||
            this.hasWritePrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.ElasticIndex,
                type: PrivilegeTypes.Edit,
            })
        );
    }

    get canViewSecurityCache(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SecurityCache,
            type: PrivilegeTypes.View,
        });
    }

    get canEditSecurityCache(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SecurityCache,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewSecurityProviders(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SecurityProvider,
            type: PrivilegeTypes.View,
        });
    }

    get canEditSecurityProviders(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SecurityProvider,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewProjects(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Project,
            type: PrivilegeTypes.View,
        });
    }

    get canEditProjects(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Project,
            type: PrivilegeTypes.Edit,
        });
    }

    get canCreateProjects(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Project,
            type: PrivilegeTypes.Create,
        });
    }

    get canViewSources(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Source,
            type: PrivilegeTypes.View,
        });
    }

    get canEditSources(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Source,
            type: PrivilegeTypes.Edit,
        });
    }

    get canCreateSources(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Source,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditAllSources(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Source,
            type: PrivilegeTypes.Edit,
            targetId: PrivilegesConstants.allTargetIds,
        });
    }

    get canViewApiKeys(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ApiKey,
            type: PrivilegeTypes.View,
        });
    }

    get canCreateApiKeys(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ApiKey,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditApiKeys(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ApiKey,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditAllApiKeys(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ApiKey,
            type: PrivilegeTypes.Edit,
            targetId: PrivilegesConstants.allTargetIds,
        });
    }

    get canViewOrganizationsExport(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.OrganizationsExport,
            type: PrivilegeTypes.View,
            level: PrivilegeLevels.Global,
        });
    }

    get canViewInfrastructure(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.Infrastructure,
            type: PrivilegeTypes.View,
        });
    }

    get canEditInfrastructure(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.Infrastructure,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditInfrastructureInReadOnly(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.Infrastructure,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewPlatformInfrastructure(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Infrastructure,
            level: PrivilegeLevels.Global,
            type: PrivilegeTypes.View,
        });
    }

    get canEditPlatformInfrastructure(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Infrastructure,
            level: PrivilegeLevels.Global,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewAllContent(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.ViewAllContent,
        });
    }

    get canPreviewContent(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.MachineLearning,
            targetDomain: PrivilegeDomains.AllowContentPreview,
        });
    }

    get canPreviewPipeline(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryPipelinePreview,
            type: PrivilegeTypes.View,
        });
    }

    get isInternalUser(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.InternalUser,
        });
    }

    get canEditSearchPages(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.SearchPages,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditAllSearchPages(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.SearchPages,
            type: PrivilegeTypes.Edit,
            targetId: PrivilegesConstants.allTargetIds,
        });
    }

    get canCreateSearchPages(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.SearchPages,
            type: PrivilegeTypes.Create,
        });
    }

    get canViewSearchPages(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.SearchPages,
            type: PrivilegeTypes.View,
        });
    }

    get canCreateExtensions(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Extensions,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditExtensions(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Extensions,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditAllExtensions(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Extensions,
            type: PrivilegeTypes.Edit,
            targetId: PrivilegesConstants.allTargetIds,
        });
    }

    get canViewExtensions(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Extensions,
            type: PrivilegeTypes.View,
        });
    }

    get canViewConditions(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.SearchApi,
                targetDomain: PrivilegeDomains.QueryPipeline,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditAllConditions(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.SearchApi,
                targetDomain: PrivilegeDomains.QueryPipeline,
                type: PrivilegeTypes.Edit,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.AnalyticsData,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.CustomDimensions,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canViewPipelines(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryPipeline,
            type: PrivilegeTypes.View,
        });
    }

    get canCreatePipelines(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryPipeline,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditPipelines(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryPipeline,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditAllPipelines(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryPipeline,
            type: PrivilegeTypes.Edit,
            targetId: PrivilegesConstants.allTargetIds,
        });
    }

    get canEditSAML(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SAMLIdentityProvider,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewSAML(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SAMLIdentityProvider,
            type: PrivilegeTypes.View,
        });
    }

    get canViewActivities(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Activities,
            type: PrivilegeTypes.View,
        });
    }

    get canViewOrganizationTemplate(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.OrganizationTemplate,
            type: PrivilegeTypes.View,
        });
    }

    get canViewNotifications(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Subscription,
            type: PrivilegeTypes.View,
        });
    }

    get canViewTemporaryAccess(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.TemporaryAccess,
            type: PrivilegeTypes.View,
        });
    }

    get canEditNotifications(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Subscription,
            type: PrivilegeTypes.Edit,
        });
    }

    get canExecuteQuery(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.ExecuteQuery,
        });
    }

    get canImpersonate(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.Impersonate,
        });
    }

    get canViewCaseAssist(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.CaseAssist,
            type: PrivilegeTypes.View,
        });
    }

    get canEditCaseAssist(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.CaseAssist,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewInsightPanel(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.InsightPanel,
            type: PrivilegeTypes.View,
        });
    }

    get canEditInsightPanel(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.InsightPanel,
            type: PrivilegeTypes.Edit,
        });
    }

    get canEditInsightPanelInterface(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.InsightPanelInterface,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewCatalogs(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Commerce,
            targetDomain: PrivilegeDomains.Catalog,
            type: PrivilegeTypes.View,
        });
    }

    get canEditCatalogs(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Commerce,
            targetDomain: PrivilegeDomains.Catalog,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewProductListings(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Commerce,
            targetDomain: PrivilegeDomains.ProductListing,
            type: PrivilegeTypes.View,
        });
    }

    get canEditProductListings(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Commerce,
            targetDomain: PrivilegeDomains.ProductListing,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewMerchandisingHub(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Commerce,
            targetDomain: PrivilegeDomains.MerchandisingHub,
            type: PrivilegeTypes.View,
        });
    }

    get canEditMerchandisingHub(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Commerce,
            targetDomain: PrivilegeDomains.MerchandisingHub,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewKnowledgeHub(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.KnowledgeHub,
            type: PrivilegeTypes.View,
        });
    }

    get canViewAnswerConfigurations(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Service,
            targetDomain: PrivilegeDomains.AnswerConfiguration,
            type: PrivilegeTypes.View,
        });
    }

    get canEditFields(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Field,
            type: PrivilegeTypes.Edit,
        });
    }

    get canCreateFields(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Field,
            type: PrivilegeTypes.Create,
        });
    }

    get canViewFields(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.Field,
            type: PrivilegeTypes.View,
        });
    }

    get canCreateResourceSnapshots(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ResourceSnapshots,
            type: PrivilegeTypes.Create,
        });
    }

    get canEditResourceSnapshots(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ResourceSnapshots,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewResourceSnapshots(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.ResourceSnapshots,
            type: PrivilegeTypes.View,
        });
    }

    get canViewSearchHubs(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.SearchHubs,
            type: PrivilegeTypes.View,
        });
    }

    get canEditSearchHubs(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.SearchHubs,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewQueryLogs(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.QueryLogs,
            type: PrivilegeTypes.View,
        });
    }

    get canViewLogicalIndex(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.LogicalIndex,
            type: PrivilegeTypes.View,
        });
    }

    get canEditMachineLearningModels(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.MachineLearning,
            targetDomain: PrivilegeDomains.MachineLearningModels,
            type: PrivilegeTypes.Edit,
        });
    }

    get canCreateMachineLearningModels(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.MachineLearning,
            targetDomain: PrivilegeDomains.MachineLearningModels,
            type: PrivilegeTypes.Create,
        });
    }

    get canViewClusters(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.OrganizationAdministration,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Infrastructure,
                type: PrivilegeTypes.View,
            })
        );
    }

    get canEditClusters(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Organization,
                type: PrivilegeTypes.View,
            }) &&
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Infrastructure,
                type: PrivilegeTypes.View,
            }) &&
            this.hasWritePrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.OrganizationAdministration,
                type: PrivilegeTypes.Edit,
            })
        );
    }

    get canViewMachineLearningModels(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.MachineLearning,
            targetDomain: PrivilegeDomains.MachineLearningModels,
            type: PrivilegeTypes.View,
        });
    }

    get canViewSnowflakeReaderAccounts(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.SnowflakeReaderAccounts,
            type: PrivilegeTypes.View,
        });
    }

    get canEditSnowflakeReaderAccounts(): boolean {
        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.UsageAnalytics,
                targetDomain: PrivilegeDomains.SnowflakeReaderAccounts,
                type: PrivilegeTypes.Edit,
            }) && this.canAdministrateUA
        );
    }

    get canViewSourceMetadata(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.SourceMetadata,
            type: PrivilegeTypes.View,
        });
    }

    get canReplayAnyQuery(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.SearchApi,
            targetDomain: PrivilegeDomains.ReplayAnyQuery,
            type: PrivilegeTypes.View,
        });
    }

    get canEditTemporaryAccess(): boolean {
        return this.hasWritePrivilege({
            owner: PrivilegeOwners.Platform,
            targetDomain: PrivilegeDomains.TemporaryAccess,
            type: PrivilegeTypes.Edit,
        });
    }

    get canViewAnalyticsData(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.AnalyticsData,
            type: PrivilegeTypes.View,
        });
    }

    get canViewProperties(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.TrackingId,
            type: PrivilegeTypes.View,
        });
    }

    get canEditProperties(): boolean {
        return this.hasPrivilege({
            owner: PrivilegeOwners.UsageAnalytics,
            targetDomain: PrivilegeDomains.TrackingId,
            type: PrivilegeTypes.Edit,
        });
    }

    hasPrivilege(privilege: PrivilegeModel | GlobalPrivilegeModel): boolean {
        return PrivilegeUtils.hasPrivilege(this.privileges, privilege);
    }

    hasOrganizationSetReadOnly(): boolean {
        return !!this.organization.readOnly;
    }

    hasWritePrivilege(privilege: PrivilegeModel | GlobalPrivilegeModel): boolean {
        return !this.hasOrganizationSetReadOnly() && this.hasPrivilege(privilege);
    }

    hasAllPrivileges(privileges: PrivilegeModel[] = []): boolean {
        return PrivilegeUtils.hasAllPrivileges(this.privileges, privileges);
    }

    hasAllViewPrivileges(privileges: PrivilegeModel[] = []): boolean {
        return every(privileges, (privilege: PrivilegeModel) =>
            privilege.type
                ? this.hasPrivilege({...privilege, type: PrivilegeTypes.View}) ||
                  this.hasPrivilege({...privilege, type: PrivilegeTypes.Allow})
                : this.hasPrivilege(privilege),
        );
    }

    canEditGroup(targetId: string): boolean {
        return targetId
            ? this.hasWritePrivilege({
                  owner: PrivilegeOwners.Platform,
                  targetDomain: PrivilegeDomains.Group,
                  type: PrivilegeTypes.Edit,
                  targetId,
              })
            : this.canEditAllGroups;
    }

    canEditSource(targetId: string): boolean {
        return targetId
            ? this.hasWritePrivilege({
                  owner: PrivilegeOwners.Platform,
                  targetDomain: PrivilegeDomains.Source,
                  type: PrivilegeTypes.Edit,
                  targetId,
              })
            : this.canEditAllSources;
    }

    canEditApiKey(targetId: string): boolean {
        return targetId
            ? this.hasWritePrivilege({
                  owner: PrivilegeOwners.Platform,
                  targetDomain: PrivilegeDomains.ApiKey,
                  type: PrivilegeTypes.Edit,
                  targetId,
              })
            : this.canEditAllApiKeys;
    }

    canEditSearchPage(targetId: string): boolean {
        return targetId
            ? this.hasWritePrivilege({
                  owner: PrivilegeOwners.SearchApi,
                  targetDomain: PrivilegeDomains.SearchPages,
                  type: PrivilegeTypes.Edit,
                  targetId,
              })
            : this.canEditAllSearchPages;
    }

    canEditExtension(targetId: string): boolean {
        return targetId
            ? this.hasWritePrivilege({
                  owner: PrivilegeOwners.Platform,
                  targetDomain: PrivilegeDomains.Extensions,
                  type: PrivilegeTypes.Edit,
                  targetId,
              })
            : this.canEditAllExtensions;
    }

    canEditPipeline(targetId: string): boolean {
        return targetId
            ? this.hasWritePrivilege({
                  owner: PrivilegeOwners.SearchApi,
                  targetDomain: PrivilegeDomains.QueryPipeline,
                  type: PrivilegeTypes.Edit,
                  targetId,
              })
            : this.canEditAllPipelines;
    }

    canEditPipelineCondition(targetId: string): boolean {
        return (
            (this.canEditPipeline(targetId) &&
                this.hasPrivilege({
                    owner: PrivilegeOwners.UsageAnalytics,
                    targetDomain: PrivilegeDomains.AnalyticsData,
                    type: PrivilegeTypes.View,
                }) &&
                this.hasPrivilege({
                    owner: PrivilegeOwners.UsageAnalytics,
                    targetDomain: PrivilegeDomains.CustomDimensions,
                    type: PrivilegeTypes.View,
                })) ||
            this.canEditAllConditions
        );
    }

    canEditProjectByResource(resource: ProjectResourceType, license: LicenseModel): boolean {
        const mapResourceTypeToPrivilege: Record<ProjectResourceType, boolean> = {
            CATALOG: this.canViewCatalogs,
            CASE_ASSIST: this.canViewCaseAssist,
            CRAWLING_MODULE:
                Boolean(license.properties?.content?.onPremisesSourcesEnabled) &&
                this.canViewSources &&
                this.canViewCrawlingModule,
            EXTENSION: this.canViewExtensions,
            IN_PRODUCT_EXPERIENCE: this.canViewSearchPages,
            INSIGHT_PANEL: this.canViewInsightPanel,
            ML_MODEL: this.canViewPipelines && this.canViewMachineLearningModels,
            QUERY_PIPELINE: this.canViewPipelines,
            SEARCH_HUB: this.canViewSearchPages && this.canViewSearchHubs,
            SEARCH_PAGE: this.canViewSearchPages,
            SECURITY_PROVIDER: this.canViewSecurityProviders,
            SOURCE: this.canViewSources,
            UA_REPORT: this.canSeeReports,
        };

        return (
            this.hasPrivilege({
                owner: PrivilegeOwners.Platform,
                targetDomain: PrivilegeDomains.Project,
                type: PrivilegeTypes.Edit,
            }) && mapResourceTypeToPrivilege[resource]
        );
    }
}
