import {
    Box,
    BoxProps,
    Factory,
    factory,
    Menu,
    MenuProps,
    StylesApiProps,
    UnstyledButton,
    useDisclosure,
    useProps,
    useStyles,
} from '@components/mantine';
import {Link} from '@core/routes';
import {ArrowHeadDownSize16Px, ArrowHeadUpSize16Px} from '@coveord/plasma-react-icons';
import {useSelector} from 'react-redux';
import {applicationsMap} from '../../constants/applicationsMap';
import {AppSwitcherSelectors} from '../../selectors/appSwitcherSelectors';
import classes from './AppSwitcher.module.css';

type AppSwitcherStylesNames = 'target' | 'dropdown' | 'item' | 'wrapper';

interface AppSwitcherProps extends BoxProps, StylesApiProps<AppSwitcherFactory> {
    /**
     * The app choice rendered.
     * @default 'platform'
     */
    app?: AppName;
    menuProps?: MenuProps;
}

export type AppSwitcherFactory = Factory<{
    props: AppSwitcherProps;
    ref: HTMLButtonElement;
    stylesNames: AppSwitcherStylesNames;
}>;

const defaultProps: Partial<AppSwitcherProps> = {
    app: 'platform',
};

export const AppSwitcher = factory<AppSwitcherFactory>((_props, ref) => {
    const {app, classNames, styles, style, className, vars, menuProps, ...others} = useProps(
        'AppSwitcher',
        defaultProps,
        _props,
    );
    const getStyles = useStyles<AppSwitcherFactory>({
        name: 'AppSwitcher',
        classes,
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });
    const [opened, {toggle}] = useDisclosure(false);
    const accessibleApps = Object.keys(applicationsMap).filter((appName) =>
        useSelector(AppSwitcherSelectors.canAccessAppSelectors[appName]),
    );
    const canAccessOtherApps = accessibleApps.length > 1;

    return (
        <Menu opened={opened} onChange={toggle} withArrow={false} width="target" {...menuProps}>
            <Menu.Target>
                <Box {...getStyles('wrapper')}>
                    <UnstyledButton
                        ref={ref}
                        disabled={!canAccessOtherApps}
                        {...getStyles('target')}
                        data-focused={opened}
                        {...others}
                    >
                        {applicationsMap[app].label}
                        {opened ? <ArrowHeadUpSize16Px height={16} /> : <ArrowHeadDownSize16Px height={16} />}
                    </UnstyledButton>
                </Box>
            </Menu.Target>
            <Menu.Dropdown {...getStyles('dropdown')}>
                {accessibleApps.map((key: AppName) => (
                    <Menu.Item
                        key={key}
                        component={Link}
                        to={applicationsMap[key].href}
                        aria-selected={key === app}
                        {...getStyles('item')}
                    >
                        {applicationsMap[key].label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
});
