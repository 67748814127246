import {Box, BoxProps, Factory, factory, List, Stack, StylesApiProps, Title, useProps} from '@coveord/plasma-mantine';
import {Children, ReactElement, ReactNode} from 'react';
import {EmptyStatePrerequisiteListItem} from './EmptyStatePrerequisiteListItem';
import {useEmptyStateContext} from './EmptyStateContext';

export type PrerequisiteListStylesName = 'prerequisiteList' | 'prerequisiteTitle' | 'prerequisiteListItemContainer';

export interface PrerequisiteListProps extends BoxProps, StylesApiProps<PrerequisiteListFactory> {
    /**
     * The prerequisite list title.
     */
    title: string;

    /**
     * The prerequisite items to display.
     */
    children: ReactNode;
}

export type PrerequisiteListFactory = Factory<{
    props: PrerequisiteListProps;
    ref: HTMLDivElement;
    stylesNames: PrerequisiteListStylesName;
    compound: true;
}>;

const defaultProps: Partial<PrerequisiteListProps> = {};

export const EmptyStatePrerequisiteList = factory<PrerequisiteListFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const props = useProps('EmptyStatePrerequisiteList', defaultProps, _props);
    const {title, children, classNames, className, styles, style} = props;

    const items = (Children.toArray(children) as ReactElement[]).filter(
        (child) => child.type === EmptyStatePrerequisiteListItem,
    );

    const stylesApiProps = {classNames, styles};

    return (
        <Box>
            <Stack
                ref={ref}
                gap="sm"
                p="sm"
                pr="md"
                display="inline-flex"
                {...ctx.getStyles('prerequisiteList', {...stylesApiProps, className, style})}
            >
                <Title order={5} {...ctx.getStyles('prerequisiteTitle', stylesApiProps)}>
                    {title}
                </Title>
                <List spacing="xs" center {...ctx.getStyles('prerequisiteListItemContainer', stylesApiProps)}>
                    {items}
                </List>
            </Stack>
        </Box>
    );
});
