import {BoxProps, ElementProps, factory, Factory, List, StylesApiProps, Text, useProps} from '@coveord/plasma-mantine';
import {CheckmarkSize16Px} from '@coveord/plasma-react-icons';
import {ReactNode} from 'react';
import {useEmptyStateContext} from './EmptyStateContext';

export type PrerequisiteItemStylesName =
    | 'prerequisiteListItem'
    | 'prerequisiteListItemText'
    | 'iconSuccess'
    | 'iconFailure';
export type PrerequisiteItemStatus = 'success' | 'failure';

export interface PrerequisiteItemProps extends BoxProps, StylesApiProps<PrerequisiteItemFactory>, ElementProps<'li'> {
    /**
     * Indicates the status of this prerequisite.
     */
    status: PrerequisiteItemStatus;

    /**
     * The content to display as the prerequisite.
     */
    children: ReactNode;
}

export type PrerequisiteItemFactory = Factory<{
    props: PrerequisiteItemProps;
    ref: HTMLLIElement;
    stylesNames: PrerequisiteItemStylesName;
    compound: true;
}>;

const defaultProps: Partial<PrerequisiteItemProps> = {};

export const EmptyStatePrerequisiteListItem = factory<PrerequisiteItemFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const props = useProps('EmptyStatePrerequisiteItem', defaultProps, _props);
    const {status, children, className, classNames, styles, style, vars: _vars, ...others} = props;
    const stylesApiProps = {classNames, styles};
    return (
        <List.Item
            ref={ref}
            {...others}
            {...ctx.getStyles('prerequisiteListItem', {...stylesApiProps, className, style})}
            icon={
                <CheckmarkSize16Px
                    {...ctx.getStyles(status === 'success' ? 'iconSuccess' : 'iconFailure', stylesApiProps)}
                    height={16}
                />
            }
        >
            <Text fz="sm" {...ctx.getStyles('prerequisiteListItemText', stylesApiProps)}>
                {children}
            </Text>
        </List.Item>
    );
});
