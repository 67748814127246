import {
    Box,
    CardProps,
    Group,
    Image,
    PolymorphicFactory,
    polymorphicFactory,
    Stack,
    StylesApiProps,
    Text,
    Title,
    useProps,
} from '@coveord/plasma-mantine';
import {ClickableCard} from '../clickable-card';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateCardStylesNames = 'card' | 'cardImage' | 'cardTitle' | 'cardBody' | 'cardDescription';

interface EmptyStateCardProps
    extends Omit<CardProps, 'classNames' | 'styles' | 'vars'>,
        StylesApiProps<EmptyStateCardFactory> {
    title: string;
    description: string;
    image: any;
}

export type EmptyStateCardFactory = PolymorphicFactory<{
    props: EmptyStateCardProps;
    stylesNames: EmptyStateCardStylesNames;
    compound: true;
    defaultComponent: 'button';
    defaultRef: HTMLDivElement;
}>;

const defaultProps: Partial<EmptyStateCardProps> = {};

export const EmptyStateCard: ReturnType<typeof polymorphicFactory<EmptyStateCardFactory>> =
    polymorphicFactory<EmptyStateCardFactory>((_props, ref) => {
        const ctx = useEmptyStateContext();
        const {title, description, image, classNames, styles, unstyled, vars, ...others} = useProps(
            'EmptyStateCard',
            defaultProps,
            _props,
        );
        const stylesApiProps = {classNames, styles};

        return (
            <ClickableCard ref={ref} {...others} {...ctx.getStyles('card', stylesApiProps)}>
                <Group gap="lg" wrap="nowrap" h="100%">
                    <Box {...ctx.getStyles('cardImage', stylesApiProps)}>
                        <Image fit="contain" w="100%" h="100%" src={image} />
                    </Box>
                    <Stack {...ctx.getStyles('cardBody', stylesApiProps)} gap="xs">
                        <Title order={4} {...ctx.getStyles('cardTitle', stylesApiProps)}>
                            {title}
                        </Title>
                        <Text size="sm" {...ctx.getStyles('cardDescription', stylesApiProps)}>
                            {description}
                        </Text>
                    </Stack>
                </Group>
            </ClickableCard>
        );
    });
