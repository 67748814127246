import {generatePaths} from '@core/routes';

export const PlatformPaths = generatePaths({
    // API Keys
    ApiKeysList: '/organization/api-access',
    ApiKeysView: '/organization/api-access/view/:id',
    ApiKeysViewOverviewTab: '/organization/api-access/view/:id/overview',
    ApiKeysEdit: '/organization/api-access/edit/:id',
    ApiKeysCreate: '/organization/api-access/create',

    // Catalogs
    CatalogsEdit: '/commerce/catalogs/edit/:id',
    CatalogsList: '/commerce/catalogs',
    CatalogsCreate: '/commerce/catalogs/create',

    // Case assist
    CaseAssist: '/service/case-assist',
    CaseAssistCreate: '/service/case-assist/create',
    CaseAssistEdit: '/service/case-assist/edit/:id',

    // Crawling module
    CrawlingModule: '/content/crawling-module',

    // Extensions
    Extensions: '/content/extensions',
    ExtensionsEdit: '/content/extensions/:id',
    ExtensionsCreate: '/content/extensions/new',

    // Insight panels
    InsightPanel: '/service/insight-panel',
    InsightPanelCreate: '/service/insight-panel/create',
    InsightPanelEdit: '/service/insight-panel/edit/:id',

    // In product experience
    Ipx: '/search/in-app-widgets',
    ClassicIpxEdit: '/search/in-app-widgets/edit/:name',

    // Pipelines
    QueryPipelines: '/search/pipelines',
    EditPipeline: '/search/pipelines/:pipelineId',
    Export: '/search/pipelines/:pipelineId/export',
    Import: '/search/pipelines/:pipelineId/import',
    Overview: '/search/pipelines/:pipelineId/overview',
    Synonyms: '/search/pipelines/:pipelineId/search-terms/synonyms',
    EditSynonym: '/search/pipelines/:pipelineId/search-terms/synonyms/:statementId',
    CopySynonym: '/search/pipelines/:pipelineId/search-terms/synonyms/:statementId/copy',
    EditSynonymCode: '/search/pipelines/:pipelineId/search-terms/synonyms/:statementId/code',
    RankingWeights: '/search/pipelines/:pipelineId/advanced/ranking-weights',
    EditRankingWeight: '/search/pipelines/:pipelineId/advanced/ranking-weights/:statementId',
    CopyRankingWeight: '/search/pipelines/:pipelineId/advanced/ranking-weights/:statementId/copy',
    EditRankingWeightCode: '/search/pipelines/:pipelineId/advanced/ranking-weights/:statementId/code',
    Triggers: '/search/pipelines/:pipelineId/advanced/triggers',
    EditTrigger: '/search/pipelines/:pipelineId/advanced/triggers/:statementId',
    CopyTrigger: '/search/pipelines/:pipelineId/advanced/triggers/:statementId/copy',
    EditTriggerCode: '/search/pipelines/:pipelineId/advanced/triggers/:statementId/code',
    Filters: '/search/pipelines/:pipelineId/advanced/filters',
    EditFilter: '/search/pipelines/:pipelineId/advanced/filters/:statementId',
    CopyFilter: '/search/pipelines/:pipelineId/advanced/filters/:statementId/copy',
    EditFilterCode: '/search/pipelines/:pipelineId/advanced/filters/:statementId/code',
    SearchSettings: '/search/pipelines/:pipelineId/advanced/search-settings',
    EditSearchSetting: '/search/pipelines/:pipelineId/advanced/search-settings/:statementId',
    CopySearchSetting: '/search/pipelines/:pipelineId/advanced/search-settings/:statementId/copy',
    EditSearchSettingCode: '/search/pipelines/:pipelineId/advanced/search-settings/:statementId/code',
    ResultRanking: '/search/pipelines/:pipelineId/result-ranking',
    CopyResultRanking: '/search/pipelines/:pipelineId/result-ranking/:resultRankingKind/:statementId/copy',
    EditResultRanking: '/search/pipelines/:pipelineId/result-ranking/:resultRankingKind/:statementId',
    EditResultRankingCode: '/search/pipelines/:pipelineId/result-ranking/:resultRankingKind/:statementId/editJSON',
    MachineLearning: '/search/pipelines/:pipelineId/machine-learning',
    EditAssociation: '/search/pipelines/:pipelineId/machine-learning/:associationId',
    EditAssociationCode: '/search/pipelines/:pipelineId/machine-learning/:associationId/json/:mode',
    StopWords: '/search/pipelines/:pipelineId/search-terms/stop',
    StatementGroups: '/search/pipelines/:pipelineId/statement-groups',
    EditStatementGroup: '/search/pipelines/:pipelineId/statement-groups/:groupId',
    ABTest: '/search/pipelines/:pipelineId/ab-test',
    RankingExpressions: '/search/pipelines/:pipelineId/ranking-expressions',

    // Conditions
    Conditions: '/search/conditions',
    EditCondition: '/search/conditions/:id',
    EditConditionNote: '/search/conditions/:id/note',
    EditConditionCode: '/search/conditions/:id/code',

    RelevanceInspector: '/search/relevanceInspector',

    // Sources
    Sources: '/content/sources',
    AddSource: '/content/sources/add',
    SubscribeSourceNotification: '/content/sources/subscribe-notifications/:sourceId/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    EditSchedulesSource: '/content/sources/editSchedules/:type/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    EditExtensionSource: '/content/sources/editExtension/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    EditJSONSource: '/content/sources/editJSON/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    DuplicateSource: '/content/sources/duplicate/:type/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    CreateSource: '/content/sources/:type/:category/create',
    CreateReactSource: '/content/sources/edit-react/:type/NEW/:tab',
    CreateBackboneSource: '/content/sources/edit/:type/NEW/:tab',
    EditSource: '/content/sources/:type/:category/edit/:id',
    EditReactSource: '/content/sources/edit-react/:type/:id/:tab',
    EditBackboneSource: '/content/sources/edit/:type/:id/:tab',
    EditMappingSource: '/content/sources/editMapping/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    EditScheduleSource: '/content/sources/editSchedules/:type/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    ActivitySource: '/content/sources/viewActivity/:id', // @todo: [CTCORE-9995] rework the source URLs, so that it can be a little more standardized
    AuthenticationFlowSource: '/sources/:type/:category/:authenticationType',
    ResumeAuthenticationFlowSource: '/sources/:type/:category/:authenticationType/resume',

    // Logs browser
    LogsBrowser: '/logs/browser',

    // Field
    EditField: '/content/fields/edit/:fieldName',

    // Content browser
    ContentBrowser: '/content/browser',

    // Metadata
    MetadataSource: '/content/sources/:sourceId/metadata',
    ViewMappingFromMetadata: '/content/sources/:sourceId/metadata/:metadataName/mappings/view',
    CreateMappingFromMetadata: '/content/sources/:sourceId/metadata/:metadataName/mappings/create',
    CreateFieldFromMetadata: '/content/sources/:sourceId/metadata/:metadataName/mappings/create/field/create',
    EditFieldFromMetadata: '/content/sources/:sourceId/metadata/:metadataName/mappings/create/field/edit/:fieldName',

    // Data Health
    EventBrowser: '/usage/health/latest-events',
    DataHealthOverview: '/usage/health/overview',
    DataHealthEventProblems: '/usage/health/event-problems',

    // Machine learning
    MachineLearningModels: '/machine-learning/models',
    MachineLearningModelsByEngineId: '/machine-learning/models/:engineId',
    AddMachineLearningModel: '/machine-learning/models/add',
    AddMachineLearningModelByType: '/machine-learning/models/add/:type',
    ModelByType: 'add/new/:type',
    EditMachineLearningModelByTab: '/machine-learning/models/details/:tab/:id',

    // Machine learning models details
    MlModelsView: '/machine-learning/models/details/information/:id',
    MlModelsEditView: '/machine-learning/models/details/configuration/:id',
    MlModelsAdvancedView: '/machine-learning/models/details/advanced/:id',

    // Projects
    CreateProject: '/organization/projects/create',
    Projects: '/organization/projects',
    ProjectActivity: '/organization/projects/activity/:projectId',
    EditProject: '/organization/projects/edit/:projectId',
    EditProjectTab: '/organization/projects/edit/:projectId/:tab',
    EditProjectTabSection: '/organization/projects/edit/:projectId/:tab/:section',
    ViewProject: '/organization/projects/view/:projectId',
    ViewProjectTab: '/organization/projects/view/:projectId/:tab',
    ViewProjectActivity: '/organization/projects/view/:projectId/:tab/activity',
    ViewProjectTabSection: '/organization/projects/view/:projectId/:tab/:section',

    // Security identities
    SecurityIdentitiesListing: '/content/security-identities',
    SecurityIdentities: '/content/security-identities/explore-identities/:id',
    SecurityIdentitiesRelationships: '/content/security-identities/relationships/:identity',

    // Reports
    Reports: '/usage/reports',
    ReportsEdit: '/usage/reports/:type/:id',

    // Advanced Reports
    AdvancedReports: '/usage/advanced-reports',
    CommerceOverview: '/usage/advanced-reports/commerce-overview',
    CommerceProductListing: '/usage/advanced-reports/commerce-product-listing',
    CommerceRecommendations: '/usage/advanced-reports/commerce-recommendations',
    CommerceSearch: '/usage/advanced-reports/commerce-search',

    // Search pages
    SearchPageView: '/searchinterfaces/:id/html',
    SearchPageListingPage: '/search/search-pages',
    CliSearchPageListingPage: '/search/search-pages/custom',

    // In product experience
    IPXListingPage: '/search/in-product-experience',

    // Storefront associations
    Storefront: '/commerce/storefront',
    StorefrontCreate: '/commerce/storefront/create',
    StorefrontEdit: '/commerce/storefront/edit/:id',
    StorefrontOverview: '/commerce/storefront/overview/:id',

    // Properties Manager
    PropertiesManager: '/usage/properties',
    PropertiesCreate: '/usage/properties/create',
    PropertiesView: '/usage/properties/view/:trackingId',
    PropertiesEdit: '/usage/properties/edit/:trackingId',
});
