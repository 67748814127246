// This file is generated by the update-rd-teams script. Run "pnpm update:rd-teams" to update.
// It needs to be pushed to the repository. Otherwise, all builds could break if a team changes.

import {type Team, type TeamProject} from './Teams.types';

export const jiraProjectsByTeam: Record<Team, TeamProject | null> = {
    'backend-foundation': {
        key: 'FOUND',
        name: 'Foundation',
        id: '12600',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-platform',
    },
    'cloud-advisor': {
        key: 'CA',
        name: 'Transformation & Enablement',
        id: '15115',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-cloud-advisers',
    },
    'cloud-infra': {
        key: 'CLOUDINFRA',
        name: 'Infrastructure',
        id: '13800',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-infra',
    },
    'cloud-intelligence': {
        key: 'SRE',
        name: 'Site Reliability Engineering',
        id: '15290',
        bugIssueTypeId: '1',
    },
    'commerce-administrators': {
        key: 'MHUB',
        name: 'Commerce Merchandising Hub',
        id: '15258',
        bugIssueTypeId: '1',
        slackSupportChannel: 'commerce-rd-administrators',
    },
    'commerce-implement': {
        key: 'LENS',
        name: 'Commerce - Implement',
        id: '15194',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-commerce',
    },
    'commerce-routemasters': {
        key: 'CAPI',
        name: 'Unified Commerce API',
        id: '15241',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-commerce',
    },
    'connectivity-core': {
        key: 'CTCORE',
        name: 'Connectivity Core',
        id: '10907',
        bugIssueTypeId: '1',
        slackSupportChannel: 'team_connectors',
    },
    ctinfra: {
        key: 'CTINFRA',
        name: 'Connectors Infrastructure',
        id: '14925',
        bugIssueTypeId: '1',
        slackSupportChannel: 'team_ct_infra',
    },
    'data-analytics-engineering': {
        key: 'BI',
        name: 'Data Analytics Engineering',
        id: '15146',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-dataplatform',
    },
    'database-data-management': {
        key: 'DDM',
        name: 'Databases and Data Management',
        id: '15259',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-dataplatform',
    },
    dataplatformfoundation: {
        key: 'UA',
        name: 'Data Platform Foundation',
        id: '10904',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-dataplatform',
    },
    'dev-tooling': {
        key: 'DT',
        name: 'CI / CD - Dev Tooling',
        id: '14907',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-dev_tooling',
    },
    documentation: {
        key: 'DOC',
        name: 'Documentation',
        id: '11300',
        bugIssueTypeId: '1',
        slackSupportChannel: 'public-documentation',
    },
    dx: {
        key: 'CDX',
        name: 'Coveo Developer Experience',
        id: '15096',
        bugIssueTypeId: '1',
        slackSupportChannel: 'searchuialertsprd',
    },
    'frontend-foundation': {
        key: 'ADUI',
        name: 'Frontend Foundation',
        id: '15157',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-admin-ui',
    },
    'index-core-search': {
        key: 'IDX',
        name: 'Index',
        id: '10903',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-index',
    },
    'index-infrastructure': {
        key: 'IDXINFRA',
        name: 'Indexing Infra',
        id: '15031',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-index-infra',
    },
    index: {
        key: 'IDX',
        name: 'Index',
        id: '10903',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-index',
    },
    'indexing-pipeline': {
        key: 'IP',
        name: 'Indexing pipeline',
        id: '14959',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-indexing-pipeline',
    },
    infrastructure: {
        key: 'CLOUDINFRA',
        name: 'Infrastructure',
        id: '13800',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-infra',
    },
    'ml-case-assist': {
        key: 'NLP',
        name: 'NLP (Legacy)',
        id: '15182',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-commerce-ai': {
        key: 'COMAI',
        name: 'Commerce AI',
        id: '15248',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-core-ai-dev': {
        key: 'COREAI',
        name: 'Core AI Dev',
        id: '15285',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-experience': {
        key: 'MLX',
        name: 'ML Platform Building',
        id: '15193',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-operations': {
        key: 'ML',
        name: 'ML-Operations',
        id: '15168',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-payg': {
        key: 'COMAI',
        name: 'Commerce AI',
        id: '15248',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-platform-building': {
        key: 'MLX',
        name: 'ML Platform Building',
        id: '15193',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-platform-serving': {
        key: 'REVEAL',
        name: 'Reveal',
        id: '12900',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-profit': {
        key: 'COMAI',
        name: 'Commerce AI',
        id: '15248',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-service-ai': {
        key: 'NLP',
        name: 'NLP (Legacy)',
        id: '15182',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-services': {
        key: 'REVEAL',
        name: 'Reveal',
        id: '12900',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    'ml-smartsnippets': {
        key: 'NLP',
        name: 'NLP (Legacy)',
        id: '15182',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-machine-learning',
    },
    offsec: {
        key: 'CSEC',
        name: 'Coveo Security',
        id: '11200',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-security',
    },
    organization: {
        key: 'ORG',
        name: 'Organization',
        id: '15243',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-platform',
    },
    'platform-foundation': {
        key: 'FOUND',
        name: 'Foundation',
        id: '12600',
        bugIssueTypeId: '1',
        slackSupportChannel: 'found_team',
    },
    'product-intelligence': null,
    'real-time-platform': {
        key: 'CCC',
        name: 'Real Time Platform',
        id: '15211',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-dataplatform',
    },
    'salesforce-integration': {
        key: 'SFINT',
        name: 'Salesforce Integration',
        id: '12000',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-service-rd',
    },
    'search-ui': {
        key: 'KIT',
        name: 'Search Kit',
        id: '15057',
        bugIssueTypeId: '1',
        slackSupportChannel: 'searchuialertsprd',
    },
    searchapi: {
        key: 'SEARCHAPI',
        name: 'Search API',
        id: '11002',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-searchapi',
    },
    'security-cache': {
        key: 'SECCACHE',
        name: 'Security Cache',
        id: '11007',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-security-cache',
    },
    security: {
        key: 'DEF',
        name: 'Security Engineering',
        id: '15212',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-security',
    },
    'service-connectivity': {
        key: 'CTSVC',
        name: 'Service Connectivity',
        id: '11004',
        bugIssueTypeId: '1',
        slackSupportChannel: 'salesforce-connectivity-alerts',
    },
    'service-core': {
        key: 'SVCC',
        name: 'Service Core',
        id: '15175',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-service-rd',
    },
    'service-data': {
        key: 'SVCDATA',
        name: 'Service Data',
        id: '15268',
        bugIssueTypeId: '1',
    },
    'service-foundation': {
        key: 'FOUND',
        name: 'Foundation',
        id: '12600',
        bugIssueTypeId: '1',
        slackSupportChannel: 'found_team',
    },
    'service-integrations': {
        key: 'SVCC',
        name: 'Service Core',
        id: '15175',
        bugIssueTypeId: '1',
    },
    sources: {
        key: 'SRC',
        name: 'Sources',
        id: '10902',
        bugIssueTypeId: '1',
        slackSupportChannel: 'sources_team',
    },
    'transformation-and-enablement': {
        key: 'CA',
        name: 'Transformation & Enablement',
        id: '15115',
        bugIssueTypeId: '1',
    },
    websites: {
        key: 'WEB',
        name: 'Websites',
        id: '10906',
        bugIssueTypeId: '1',
        slackSupportChannel: 'support-websites',
    },
    wpc: {
        key: 'WPC',
        name: 'Workplace Connectivity',
        id: '15244',
        bugIssueTypeId: '1',
    },
};
